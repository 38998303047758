import React from "react";
import Section from "../components/Section";

import "./TestContent.scss";

export default function TestContent() {
	// need to match maps in TestContent.scss
	const testFontSizes = {
		375: {
			base: 16,
			ratio: 1.125,
		},
		1440: {
			base: 20,
			ratio: 1.333,
		},
	};

	// need to match maps in TestContent.scss
	const testSpacingSizes = {
		375: {
			base: 2,
			ratio: 2,
		},
		900: {
			base: 3,
			ratio: 2,
		},
		1440: {
			base: 4,
			ratio: 2,
		},
	};
	// need to match maps in TestContent.scss
	const testSpacingScales = {
		xxs: 0,
		xs: 1,
		s: 2,
		m: 3,
		l: 4,
		xl: 5,
		xxl: 6,
	};

	// need to match min and max breakpoints in ratioStandard mixin
	const minBreakpoint = 320;
	const maxBreakpoint = 2200;

	// sizes I was to print out
	const printedSizes = [375, 900, 1440, 2560];

	const valueBreakpoint = (scale, breakpoint, sizes, restricted) => {
		if (Object.keys(sizes).length >= 2) {
			const resrictedBreakpoint = Math.max(
				minBreakpoint,
				Math.min(maxBreakpoint, breakpoint)
			);

			const smallerBreakpoints = Object.keys(sizes).filter(
				(size) => parseInt(size) <= breakpoint
			);
			const largerBreakpoints = Object.keys(sizes).filter(
				(size) => parseInt(size) > breakpoint
			);

			const maxSmallerBreakpoint =
				smallerBreakpoints.length > 0 ? Math.max(...smallerBreakpoints) : null;
			const minLargerBreakpoint =
				largerBreakpoints.length > 0 ? Math.min(...largerBreakpoints) : null;

			let fromBreakpoint = maxSmallerBreakpoint;
			let toBreakpoint = minLargerBreakpoint;

			if (!fromBreakpoint) {
				const largerBreakpointsWithoutMin = largerBreakpoints.filter(
					(size) => parseInt(size) !== minLargerBreakpoint
				); // array of largerBreakpoints without min (since it'll be used for FromBreakpoint)
				const secondMinLargerBreakpoint = Math.min(
					...largerBreakpointsWithoutMin
				);

				fromBreakpoint = minLargerBreakpoint;
				toBreakpoint = secondMinLargerBreakpoint;
			}

			if (!toBreakpoint) {
				const smallerBreakpointsWithoutMax = smallerBreakpoints.filter(
					(size) => parseInt(size) !== maxSmallerBreakpoint
				); // array of smallerBreakpoints without max (since it'll be used for toBreakpoint)
				const secondMaxSmallerBreakpoint = Math.max(
					...smallerBreakpointsWithoutMax
				);

				fromBreakpoint = secondMaxSmallerBreakpoint;
				toBreakpoint = maxSmallerBreakpoint;
			}

			const from =
				sizes[fromBreakpoint].base *
				Math.pow(sizes[fromBreakpoint].ratio, scale);
			const to =
				sizes[toBreakpoint].base * Math.pow(sizes[toBreakpoint].ratio, scale);

			const slope = (to - from) / (toBreakpoint - fromBreakpoint);
			const base = from - slope * fromBreakpoint;

			const unrestrictedValue = `${Math.round(base + breakpoint * slope)}px`;
			const restrictedValue = `${Math.round(
				base + resrictedBreakpoint * slope
			)}px`;

			return restricted ? restrictedValue : unrestrictedValue;
		}
	};

	const values = (scale, breakpoints, sizes) => {
		const arrayOfStrings = [];
		breakpoints.forEach((breakpoint) => {
			arrayOfStrings.push(
				`${breakpoint}: ${valueBreakpoint(scale, breakpoint, sizes)}`
			);
		});

		return arrayOfStrings.join("<br />");
	};

	return (
		<Section className="test-content">
			<div className="test-content__container">
				<div className="test-content__text">
					<h1>Heading 1</h1>
					<p>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
						convallis tellus ex, scelerisque porttitor orci mattis sed.
						Phasellus lobortis tristique dapibus. Ut nisl nisl, faucibus ac
						nulla pretium, posuere bibendum ex. Donec nec enim mi. Sed
						scelerisque laoreet lobortis.
					</p>
					<h2>Heading 2</h2>
					<p>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
						convallis tellus ex, scelerisque porttitor orci mattis sed.
						Phasellus lobortis tristique dapibus. Ut nisl nisl, faucibus ac
						nulla pretium, posuere bibendum ex. Donec nec enim mi. Sed
						scelerisque laoreet lobortis.
					</p>
					<h3>Heading 3</h3>
					<p>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
						convallis tellus ex, scelerisque porttitor orci mattis sed.
						Phasellus lobortis tristique dapibus. Ut nisl nisl, faucibus ac
						nulla pretium, posuere bibendum ex. Donec nec enim mi. Sed
						scelerisque laoreet lobortis.
					</p>
					<h4>Heading 4</h4>
					<p>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
						convallis tellus ex, scelerisque porttitor orci mattis sed.
						Phasellus lobortis tristique dapibus. Ut nisl nisl, faucibus ac
						nulla pretium, posuere bibendum ex. Donec nec enim mi. Sed
						scelerisque laoreet lobortis.
					</p>
					<h5>Heading 5</h5>
					<p>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
						convallis tellus ex, scelerisque porttitor orci mattis sed.
						Phasellus lobortis tristique dapibus. Ut nisl nisl, faucibus ac
						nulla pretium, posuere bibendum ex. Donec nec enim mi. Sed
						scelerisque laoreet lobortis.
					</p>
					<h6>Heading 6</h6>
					<p>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
						convallis tellus ex, scelerisque porttitor orci mattis sed.
						Phasellus lobortis tristique dapibus. Ut nisl nisl, faucibus ac
						nulla pretium, posuere bibendum ex. Donec nec enim mi. Sed
						scelerisque laoreet lobortis.
					</p>
					<p class="meta">
						Meta or paragraph small text here. Lorem ipsum dolor sit amet,
						consectetur adipiscing elit. Duis convallis tellus ex, scelerisque
						porttitor orci mattis sed. Phasellus lobortis tristique dapibus. Ut
						nisl nisl, faucibus ac nulla pretium, posuere bibendum ex. Donec nec
						enim mi. Sed scelerisque laoreet lobortis.
					</p>
				</div>
				<div className="test-content__spacing">
					{Object.keys(testSpacingScales).map(() => (
						<div></div>
					))}
				</div>
				<div className="test-content__values">
					<h2>Values at Design Breakpoints</h2>
					<h3>Font Sizes</h3>
					<h4>Heading 1</h4>
					<p
						dangerouslySetInnerHTML={{
							__html: values(6, printedSizes, testFontSizes),
						}}
					/>
					<h4>Heading 2</h4>
					<p
						dangerouslySetInnerHTML={{
							__html: values(5, printedSizes, testFontSizes),
						}}
					/>
					<h4>Heading 3</h4>
					<p
						dangerouslySetInnerHTML={{
							__html: values(4, printedSizes, testFontSizes),
						}}
					/>
					<h4>Heading 4</h4>
					<p
						dangerouslySetInnerHTML={{
							__html: values(3, printedSizes, testFontSizes),
						}}
					/>
					<h4>Heading 5</h4>
					<p
						dangerouslySetInnerHTML={{
							__html: values(2, printedSizes, testFontSizes),
						}}
					/>
					<h4>Heading 6</h4>
					<p
						dangerouslySetInnerHTML={{
							__html: values(1, printedSizes, testFontSizes),
						}}
					/>
					<h4>P Standard</h4>
					<p
						dangerouslySetInnerHTML={{
							__html: values(0, printedSizes, testFontSizes),
						}}
					/>
					<h3>Spacing Sizes</h3>
					<h4>xxs</h4>
					<p
						dangerouslySetInnerHTML={{
							__html: values(
								testSpacingScales.xxs,
								printedSizes,
								testSpacingSizes
							),
						}}
					/>
					<h4>xs</h4>
					<p
						dangerouslySetInnerHTML={{
							__html: values(
								testSpacingScales.xs,
								printedSizes,
								testSpacingSizes
							),
						}}
					/>
					<h4>s</h4>
					<p
						dangerouslySetInnerHTML={{
							__html: values(
								testSpacingScales.s,
								printedSizes,
								testSpacingSizes
							),
						}}
					/>
					<h4>m</h4>
					<p
						dangerouslySetInnerHTML={{
							__html: values(
								testSpacingScales.m,
								printedSizes,
								testSpacingSizes
							),
						}}
					/>
					<h4>l</h4>
					<p
						dangerouslySetInnerHTML={{
							__html: values(
								testSpacingScales.l,
								printedSizes,
								testSpacingSizes
							),
						}}
					/>
					<h4>xl</h4>
					<p
						dangerouslySetInnerHTML={{
							__html: values(
								testSpacingScales.xl,
								printedSizes,
								testSpacingSizes
							),
						}}
					/>
					<h4>xxl</h4>
					<p
						dangerouslySetInnerHTML={{
							__html: values(
								testSpacingScales.xxl,
								printedSizes,
								testSpacingSizes
							),
						}}
					/>
				</div>
			</div>
		</Section>
	);
}
