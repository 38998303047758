import React from "react";
import Layout from "../components/Layout";
import { LayoutModeProvider } from "../containers/LayoutModeContext";
import TestContent from "../partials/TestContent";

export default function IndexPage() {
	return (
		<LayoutModeProvider mode="standard">
			<Layout>
				<TestContent />
			</Layout>
		</LayoutModeProvider>
	);
}
